/*@font-face {*/
/*    font-family: 'Spoqa Han Sans Neo';*/
/*    font-weight: 700;*/
/*    src: !*local('Spoqa Han Sans Neo Bold'),*!*/
/*    url('https://wheelchairkorea.cdn.ntruss.com/file/static/font/SpoqaHanSansNeo-Bold.woff2') format('woff2'),*/
/*    url('https://wheelchairkorea.cdn.ntruss.com/file/static/font/SpoqaHanSansNeo-Bold.woff') format('woff'),*/
/*    url('https://wheelchairkorea.cdn.ntruss.com/file/static/font/SpoqaHanSansNeo-Bold.ttf') format('truetype');*/
/*    font-display: block;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Spoqa Han Sans Neo';*/
/*    font-weight: 400;*/
/*    src: !*local('Spoqa Han Sans Neo Regular'),*!*/
/*    url('https://wheelchairkorea.cdn.ntruss.com/file/static/font/SpoqaHanSansNeo-Regular.woff2') format('woff2'),*/
/*    url('https://kr.object.fin-ncloudstorage.com/wheelchairkoㅈrea/file/static/font/SpoqaHanSansNeo-Regular.woff') format('woff'),*/
/*    url('https://wheelchairkorea.cdn.ntruss.com/file/static/font/SpoqaHanSansNeo-Regular.ttf') format('truetype');*/
/*    font-display: block;*/

/*}*/

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: local('Spoqa Han Sans Neo Bold'),
    url('./styles/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('./styles/fonts/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('./styles/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    src: local('Spoqa Han Sans Neo Regular'),
    url('./styles/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('./styles/fonts/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('./styles/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
    font-display: block;

}

